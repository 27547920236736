/* eslint-disable standard/no-callback-literal */
const baseRequirement = {
  required: true, message: 'Please fill in the correct value', trigger: 'blur'
}
const baseFileRequirement = {
  required: true, message: 'Please Upload the correct file', trigger: 'blur'
}
const BaseSignRequirement = {
  required: true, message: 'Please Sign', trigger: 'blur'
}
const emailValidator = {
  required: true, type: 'email', message: 'Please input correct email', trigger: 'blur'
}
const checkTrue = function (rule, value, callback) {
  if (value === true) {
    callback()
  } else {
    callback(false)
  }
}
const trueValidator = {
  required: true, validator: checkTrue, message: 'Please read and tick', trigger: 'change'
}
const rules = {
  entity_broker_company_address: [
    baseRequirement
  ],
  entity_broker_company_name: [
    baseRequirement
  ],
  entity_broker_dob: [
    baseRequirement
  ],
  entity_broker_surname: [
    baseRequirement
  ],
  entity_broker_forename: [
    baseRequirement
  ],

  entity_broker_tile: [
    baseRequirement
  ],
  entity_broker_gender: [
    baseRequirement
  ],
  entity_broker_gst_number: [
    baseRequirement
  ],
  entity_broker_is_gst_registered: [
    baseRequirement
  ],
  entity_broker_address: [
    baseRequirement
  ],
  entity_broker_phone_number: [
    baseRequirement
  ],
  entity_broker_emaill: [
    emailValidator
  ],
  entity_broker_sign_key: [
    BaseSignRequirement
  ],
  entity_broker_id_type: [
    baseRequirement
  ],
  entity_broker_cn_identify_front_num: [
    baseRequirement
  ],
  entity_broker_cn_identify_front_country: [
    baseRequirement
  ],
  entity_broker_cn_identify_front_file_list: [
    baseFileRequirement
  ],
  entity_broker_cn_identify_back_file_list: [
    baseFileRequirement
  ],
  entity_broker_nz_au_driver_num: [
    baseRequirement
  ],
  entity_broker_nz_au_driver_country: [
    baseRequirement
  ],
  entity_broker_nz_au_driver_front_file_list: [
    baseFileRequirement
  ],
  entity_broker_nz_au_driver_back_file_list: [
    baseFileRequirement
  ],
  entity_broker_nz_au_passport_num: [
    baseRequirement
  ],
  entity_broker_nz_au_passport_country: [
    baseRequirement
  ],
  entity_broker_nz_au_passport_file_list: [
    baseFileRequirement
  ],
  entity_broker_agreement: [
    trueValidator
  ]
}
export default rules
