<template>
    <div class="max-w-full sm:max-w-xl bg-gray-200  pt-4 sm:pt-2">
        <div
            class="
                my-2
                sm:my-6
                text-2xl
                sm:text-4xl
                text-center
                font-500
                text-blue-800
            "
        >
            Affiliate account registration
        </div>
        <div class="text-base sm:text-22px font-500 text-blue-800 text-center">
            Contact Information
        </div>
        <div class="text-base sm:text-18px font-400 text-blue-800 text-center">
           (请填写个人信息)
        </div>
        <div class="form_container">
            <a-form-model
                ref="ruleForm"
                :rules="rules"
                layout="horizontal"
                :model="brokerForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <div>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            :style="GetScreenStyle.width45percent"
                            prop="broker_surname"
                            label="First name"
                        >
                            <a-input
                                :disabled="disabled"
                                name="broker_surname"
                                @change="handleChange"
                                v-model="brokerForm.broker_surname"
                                placeholder="First name"
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            :style="GetScreenStyle.width45percent"
                            prop="broker_forename"
                            label="Last name"
                        >
                            <a-input
                                v-model="brokerForm.broker_forename"
                                :disabled="disabled"
                                placeholder="Last name"
                            />
                        </a-form-model-item>
                    </div>
                    <div class="mutiple_form_layout">
                        <a-form-model-item
                            prop="broker_maiden_name"
                            :style="GetScreenStyle.width45percent"
                            label="Maiden Name"
                        >
                            <a-input
                                v-model="brokerForm.broker_maiden_name"
                                :disabled="disabled"
                                placeholder="Maiden Name if applicable"
                            />
                        </a-form-model-item>
                        <a-form-model-item
                            prop="broker_preferred_name"
                            :style="GetScreenStyle.width45percent"
                            label="Preferred Name"
                        >
                            <a-input
                                v-model="brokerForm.broker_preferred_name"
                                :disabled="disabled"
                                placeholder="Preferred Name if applicable"
                            />
                        </a-form-model-item>
                    </div>
                    <a-form-model-item
                        :style="GetScreenStyle.width45percent"
                        prop="broker_tile"
                        label="Title"
                    >
                    <a-select
                        v-model="brokerForm.broker_tile"
                        :disabled="disabled"
                      >
                        <a-select-option value="Mr"> Mr </a-select-option>
                        <a-select-option value="Miss"> Miss </a-select-option>
                        <a-select-option value="Ms"> Ms </a-select-option>
                        <a-select-option value="Other"> Other </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        :wrapper-col="inlineStyle"
                        prop="broker_gender"
                        label="Gender:"
                    >
                        <a-radio-group
                        :disabled="disabled"
                        v-model="brokerForm.broker_gender">
                            <a-radio value="1"> Male </a-radio>
                            <a-radio value="2"> Female </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        :wrapper-col="inlineStyle"
                        prop="broker_is_nz_taxer"
                        label="AU Tax Resident"
                    >
                        <a-radio-group
                        :disabled="disabled"
                        v-model="brokerForm.broker_is_nz_taxer">
                            <a-radio :value="true"> Yes </a-radio>
                            <a-radio :value="false"> No </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width60percent"
                        v-if="broker_is_nz_taxer"
                        prop="broker_tax_num"
                        label="NZ Ird Num:"
                    >
                        <a-input
                            v-model="brokerForm.broker_tax_num"
                            :disabled="disabled"
                            placeholder="NZ IRD Number"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        :wrapper-col="inlineStyle"
                        prop="broker_is_gst_registered"
                        label="GST Registered "
                    >
                        <a-radio-group
                            :disabled="disabled"
                            v-model="brokerForm.broker_is_gst_registered"
                        >
                            <a-radio :value="true"> Yes </a-radio>
                            <a-radio :value="false"> No </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width60percent"
                        v-if="broker_is_gst_registered"
                        prop="broker_gst_number"
                        label="GST Registered"
                    >
                        <a-input
                            v-model="brokerForm.broker_gst_number"
                            :disabled="disabled"
                            placeholder="GST_Registered"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        prop="broker_dob"
                        label="Date of birth 生日"
                    >
                        <a-date-picker
                            @change="
                                (e) => {
                                    handleDatePick(e, 'broker_dob')
                                }
                            "
                            type="date"
                            :disabled="disabled"
                            :value="broker_dob_formate"
                            :inputReadOnly="true"
                            placeholder="Pick a date"
                            style="width: 100%"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        prop="broker_address"
                        label="Home Address 家庭住址"
                    >
                        <BaseAddress
                          prop="broker_address"
                          :disabled="disabled"
                          :value="brokerForm.broker_address"
                          @handleChange='(variable,value)=> {
                            changeFormAction({variable,value})
                          }'
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        prop="broker_home_number"
                        label="Home Telephone 家庭电话"
                    >
                        <a-input
                            v-model="brokerForm.broker_home_number"
                            :disabled="disabled"
                            placeholder="Home Telephone"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        prop="broker_phone_number"
                        label="Mobile Phone 手机号码"
                    >
                        <a-input
                            v-model="brokerForm.broker_phone_number"
                            :disabled="disabled"
                            placeholder="Mobile Phone"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        prop="broker_emaill"
                        label="Email Address 电子邮箱"
                    >
                        <a-input
                            v-model="brokerForm.broker_emaill"
                            :disabled="disabled"
                            placeholder="Email Address"
                        />
                    </a-form-model-item>
                    <a-form-model-item
                        :style="GetScreenStyle.width100percent"
                        prop="broker_id_type"
                        label="Identification type 证件类型"
                    >
                        <a-select
                            :disabled="disabled"
                            v-model="brokerForm.broker_id_type"
                            placeholder="Please select your identification type"
                        >
                            <a-select-option value="nz_au_Passport">
                                AU/NZ Passport 澳大利亚/新西兰护照
                            </a-select-option>
                            <a-select-option value="nz_au_driver_licence"
                                >AU/NZ driver licence
                                澳大利亚/新西兰驾照</a-select-option
                            >
                            <a-select-option value="cn_identify"
                                >Chinese ID card 中国身份证
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item prop="broker_id_file">
                    </a-form-model-item>
                    <div v-if="broker_id_type">
                        <AFileMessage
                            :disabled="disabled"
                            :obj="idProve[broker_id_type]"
                            @handleFileChange="handleFileList"
                            @handleInput="handleInput"
                            @FileValidate="FileValidate"
                            @handleSelect="handleSelect"
                        />
                    </div>
                    <a-form-model-item prop="broker_agreement">
                        <a-checkbox
                            class="antd_checkbox"
                            :disabled="disabled"
                            v-model="brokerForm.broker_agreement"
                        >
                            <span class="text-base">
                                I agree with the
                                <a class="underline" @click="handleAgreement"
                                    >term and condition</a
                                >
                            </span>
                        </a-checkbox>
                    </a-form-model-item>
                    <h2 class="step_question_title">Signature 申请人签字</h2>
                    <p class="claim_text">
                        I declare all of the information I have provided above
                        is correct. 我声明我上面提供的所有信息都是正确的。
                    </p>
                    <a-form-model-item ref="self_sign" prop="broker_sign_key">
                        <Signature
                            ref='brokerSign'
                            :disabled="disabled"
                            name="broker_sign_key"
                            class="sign"
                            :outsourceSign="broker_sign"
                            @upLoadSignKey="handleSignUpload"
                        ></Signature>
                    </a-form-model-item>
                    <div class="flex justify-center">
                        <a-form-model-item
                            :style="GetScreenStyle.width30percent"
                        >
                            <a-button
                                :disabled="disabled"
                                @click="onSubmit"
                                :style="{ width: '100%' }"
                                type="primary"
                            >
                                Submit
                            </a-button>
                        </a-form-model-item>
                    </div>
                </div>
            </a-form-model>
        </div>
    </div>
</template>

<script>

import Signature from '@/components/Signature.vue'
import AFileMessage from '@/components/AFileMessage'
import { mapState, mapActions, mapGetters } from 'vuex'
import { getAwsPic, brokerFormCreate } from '@/api/common'
// import BaseImg from '@/components/BaseImg.vue'
import rules from '@/assets/rules/brokerRules.js'
import BaseAddress from '@/components/BaseAddress.vue'
import moment from 'moment'

export default {
  components: {
    Signature,
    AFileMessage,
    BaseAddress
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.rules = rules
    if (this.broker_sign_key) {
      this.fetchSign(this.broker_sign_key, 'broker_sign')
    } else {
      this.broker_sign = null
    }
  },
  methods: {
    closeSignPage () {
      this.$refs.brokerSign.closeSignPage()
    },
    ...mapActions('brokerForm', ['changeFormAction']),
    handleAgreement () {
      window.open('https://public-nzsky-files.s3.ap-southeast-2.amazonaws.com/Referrer_Agreement.pdf')
    },
    fetchSign (from, x) {
      const query = {
        key: from
      }
      getAwsPic(query).then(res => {
        if (res.url) {
          this[x] = res.url
        }
      })
    },
    handleDatePick (value, variable) {
      if (value) {
        const _value = value.format()
        this.changeFormAction({ variable, value: _value })
      }
      this.FileValidate(variable)
    },
    handleSignUpload (variable, val) {
      this.changeFormAction({ variable, value: val })
        .then(() => {
          this.FileValidate(variable)
        })
    },
    handleChange (e) {
      const { name } = e.target
      const value = e.target.value.replace(/[\u4E00-\u9FA5]/g, '')

      this.changeFormAction({ variable: name, value })
    },
    onSubmit () {
      const query = { referrer_type: 'I', ...this.GetBrokerForm }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          brokerFormCreate(query).then((res) => {
          }).then(() => {
            this.$emit('formSubmit')
            this.$message.success('上传成功')
          }).catch(() => {
            this.$message.error('Sever is busy')
          })
        } else {
          return false
        }
      })
    },
    handleFileList (fileList, variable) {
      this.changeFormAction({ variable, value: fileList })
    },
    handleInput (e, variable) {
      const { value } = e.target
      const _value = value.replace(/[\u4E00-\u9FA5]/g, '')
      this.changeFormAction({ variable, value: _value })
    },
    FileValidate (val) {
      if (val) {
        this.$refs.ruleForm.validateField([val])
      } else {
        this.$refs.ruleForm.validate()
      }
    },
    handleSelect (value, variable) {
      this.changeFormAction({ variable, value })
    }
  },
  watch: {
    broker_sign_key (newValue) {
      if (newValue) {
        this.fetchSign(newValue, 'broker_sign')
      } else {
        this.broker_sign = null
      }
    }
  },
  data () {
    return {
      broker_sign: null,
      self_sign: '',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      inlineStyle: {
        span: 12, offset: 1
      },
      btnStyle: {
        span: 24, offset: 7
      },
      rules: null
    }
  },
  computed: {
    idProve () {
      return {
        nz_au_Passport: this.nzPassportFile,
        nz_au_driver_licence: this.nzDriverFile,
        cn_identify: this.cnIdentify
      }
    },
    cnIdentify () {
      return {
        desc: 'Chinese National ID (the copy of front and back side 身份证正反面) ',
        id: 5,
        message: [
          {
            mid: 3,
            proof_num: this.broker_cn_identify_front_num,
            proof_num_str: 'broker_cn_identify_front_num',
            belongs_to: this.broker_cn_identify_front_country,
            belongs_to_str: 'broker_cn_identify_front_country',
            belongs_to_str_disabled: false,
            country_selection: [
              {
                name: 'China',
                dial_code: '+86',
                code: 'CN'
              }
            ]
          }
        ],
        upload: [
          {
            fid: 6,
            title: '中国身份证正面',
            prop: 'broker_cn_identify_front_file_list',
            file: this.broker_cn_identify_front_file_list
          },
          {
            fid: 7,
            title: '中国身份证反面',
            prop: 'broker_cn_identify_back_file_list',
            file: this.broker_cn_identify_back_file_list
          }
        ]
      }
    },
    nzDriverFile () {
      return {
        id: 2,
        desc: 'Australia/New Zealand driver licence (the copy of front and back side 澳大利亚/新西兰驾照正反面)',
        message: [{
          mid: 1,
          proof_num: this.broker_nz_au_driver_num,
          proof_num_str: 'broker_nz_au_driver_num',
          belongs_to: this.broker_nz_au_driver_country,
          belongs_to_str: 'broker_nz_au_driver_country',
          belongs_to_str_disabled: false,
          country_selection: [
            {
              name: 'Australia',
              dial_code: '+61',
              code: 'AU'
            },
            {
              name: 'New Zealand',
              dial_code: '+64',
              code: 'NZ'
            }
          ]
        }],
        upload: [
          {
            fid: 2,
            title: '澳大利亚/新西兰驾照正面',
            prop: 'broker_nz_au_driver_front_file_list',
            file: this.broker_nz_au_driver_front_file_list
          },
          {
            fid: 3,
            title: '澳大利亚/新西兰驾照反面',
            prop: 'broker_nz_au_driver_back_file_list',
            file: this.broker_nz_au_driver_back_file_list
          }
        ]
      }
    },
    nzPassportFile () {
      return {
        id: 15,
        desc: 'Australia/New Zealand Passport 澳大利亚/新西兰护照 (the copy of passport photo and signature page 护照信息页和签字页) ',
        message: [{
          mid: 52,
          proof_num: this.broker_nz_au_passport_num,
          proof_num_str: 'broker_nz_au_passport_num',
          belongs_to: this.broker_nz_au_passport_country,
          belongs_to_str: 'broker_nz_au_passport_country',
          country_selection: [
            {
              name: 'Australia',
              dial_code: '+61',
              code: 'AU'
            },
            {
              name: 'New Zealand',
              dial_code: '+64',
              code: 'NZ'
            }
          ]
        }],
        upload: [
          {
            fid: 53,
            title: 'Australia/New Zealand护照信息页+签字页',
            prop: 'broker_nz_au_passport_file_list',
            file: this.broker_nz_au_passport_file_list
          }
        ]
      }
    },
    broker_dob_formate () {
      if (this.broker_dob) {
        return moment(this.broker_dob)
      }
      return null
    },
    brokerForm () {
      return this.$store.state.brokerForm
    },
    ...mapGetters('layout', ['GetScreenStyle']),
    ...mapGetters('brokerForm', ['GetBrokerForm']),
    ...mapState('brokerForm', ['broker_dob', 'broker_sign_key', 'broker_is_nz_taxer', 'broker_is_gst_registered', 'broker_id_type', 'broker_cn_identify_front_num', 'broker_cn_identify_front_country', 'broker_cn_identify_front_file_list', 'broker_cn_identify_back_file_list', 'broker_nz_au_driver_num', 'broker_nz_au_driver_country', 'broker_nz_au_driver_front_file_list', 'broker_nz_au_driver_back_file_list', 'broker_nz_au_passport_num', 'broker_nz_au_passport_country', 'broker_nz_au_passport_file_list'])
  }
}
</script>
<style scoped>
#components-form-demo-validate-other .dropbox {
    height: 180px;
    line-height: 1.5;
}
.mutiple_form_layout {
    display: flex;
    justify-content: space-between;
}
.form_container {
    margin: 1rem auto 0;
    padding: 0 1rem 1rem;
}
.sign >>> .img_container {
    margin: 1.5rem auto;
}
.antd_checkbox >>> .ant-checkbox-wrapper {
    font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-inner {
    width: 30px;
    height: 30px;
}
.antd_checkbox >>> .ant-checkbox-inner::after {
    width: 9px;
    height: 18px;
}
@media only screen and (max-width: 768px) {
    .form_container {
        padding: 5vw;
    }
}
</style>
