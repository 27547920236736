<template>
  <div class=" rounded container">
    <div class=" text-1000rem sm:text-xl text-center sm:px-4 px-1 mb-4">{{obj.desc}}</div>
    <div
    v-for="item in obj.message"
    :key="item.mid"
    class="mutiple_form_layout">
    <a-form-model-item
      :style="GetScreenStyle.width45percent"
      label="Id number(证件号码)"
      :prop="item.proof_num_str"
    >
      <a-input
        :disabled='disabled'
        @change="
          (e) => {
            handleInput(e, item.proof_num_str);
          }
        "
        :value="item.proof_num"
      />
    </a-form-model-item>
    <a-form-model-item
      :style="GetScreenStyle.width45percent"
      label="Issue Country(所属国家)"
      :prop="item.belongs_to_str"
    >
    <a-select
        @change="
          (value) => {
            handleSelect(value, item.belongs_to_str);
          }
        "
        :disabled='item.belongs_to_str_disabled || disabled'
        :value="item.belongs_to"
      >
          <a-select-option
            v-for="item of item.country_selection"
            v-bind:key="item.code"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
    </a-form-model-item>
  </div>
      <AUploadFile
        v-for="item in obj.upload"
        :key="item.fid"
        :title="item.title"
        :prop="item.prop"
        :fileList="item.file"
        @FileValidate='FileValidate'
        @handleFileChange="handleFileChange"
        :disabled='disabled'
      />
  </div>
</template>

<script>
import AUploadFile from './AUploadFile'
import { mapGetters } from 'vuex'

export default {
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    FileValidate (e) {
      this.$emit('FileValidate', e)
    },
    handleInput (e, val) {
      this.$emit('handleInput', e, val)
    },
    handleFileChange (e, val) {
      this.$emit('handleFileChange', e, val)
    },
    handleSelect (e, val) {
      this.$emit('handleSelect', e, val)
    }
  },

  components: {
    AUploadFile
  },
  computed: {
    ...mapGetters('layout', ['GetScreenStyle'])
  }
}
</script>

<style scoped>
.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
  margin-top: .5rem;
}
.container{
  border: 1px solid rgba(0,0,0,.65);
  padding: 1rem;
  margin-bottom: .5rem;
}
@media only screen and (max-width: 768px) {
  .mutiple_form_layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

</style>
