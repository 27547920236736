<template>
  <div>
    <div class="dash_title">
      Dash Board
    </div>
    <div class="dash_bottom">
      <transition name="fade-leave">
        <div class="dash_info p-3 rounded-lg shadow-md">
          <div class=" text-xl font-500">账户验证/User Vertification</div>

          <div class="mt-2 flex items-center justify-between">
            <div class=" font-400">证件上传状态(File Upload Status)</div>
            <div  class="">{{irdStatus[getIrdStatus].desc}}</div>
          </div>
          <div class="mt-2 flex items-center justify-between">
            <div class=" font-400">开户状态(Account Status)</div>
            <div v-if="getBrokerAvaliable">Active</div>
            <div v-if="!getBrokerAvaliable" @click="handleShowForm" class="underline cursor-pointer">VERTIFY</div>
            <div v-else @click="handleShowForm" class="underline cursor-pointer">view information</div>
          </div>
        </div>
      </transition>
      <a-modal
        v-model="selectionModalShow"
        title="开户类型(Account Type)"
        okText='Next'
        @ok="()=> {handleNext(accountType)}"
        @cancel='selectionModalShow = false'
      >
        <a-radio-group v-model="accountType" class="flex justify-evenly" size="large">
          <a-radio-button value="personal">
            个人(personal)
          </a-radio-button>
          <a-radio-button value="entity">
            企业(entity)
          </a-radio-button>
      </a-radio-group>
      </a-modal>
          <div class=" mt-4">
          <BrokerForm
            class=" mt-4"
            :disabled="getFormIsApproved"
            @formSubmit='formSubmit'
            v-if="personCardShow"
          />
          <BrokerEntityForm
            class=" mt-4"
            :disabled="getFormIsApproved"
            @formSubmit='formSubmit'
            v-if="entityCardShow"
          />
          </div>
    </div>
  </div>
</template>

<script>
import BrokerForm from '@/components/Broker/BrokerForm.vue'
import BrokerEntityForm from '@/components/Broker/BrokerEntityForm.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  created () {
  },
  components: {
    BrokerForm,
    BrokerEntityForm
  },
  data () {
    return {
      accountType: 'personal',
      selectionModalShow: false,
      irdStatus: {
        none: {
          desc: 'Pending'
        },
        wait: {
          desc: 'Pending'
        },
        success: {
          desc: 'Active'
        }
      },
      personCardShow: false,
      entityCardShow: false
    }
  },
  methods: {
    formSubmit () {
      this.personCardShow = false
      this.entityCardShow = false
      this.SetBrokerAction()
    },
    handleNext (accountType) {
      this.selectionModalShow = false
      if (accountType === 'personal') {
        this.showIndividualForm()
      } else {
        this.showEntityForm()
      }
    },
    showIndividualForm () {
      this.personCardShow = true
      this.entityCardShow = false
    },
    showEntityForm () {
      this.personCardShow = false
      this.entityCardShow = true
    },
    handleShowForm () {
      if (this.getFormIsSubmit) {
        if (this.getIsIndividual) {
          this.showIndividualForm()
        } else {
          this.showEntityForm()
        }
      } else if (this.getFormIsApproved) {
        if (!this.getIsIndividual) {
          this.showEntityForm()
        } else {
          this.showIndividualForm()
        }
      } else {
        this.selectionModalShow = true
      }
    }
  },
  beforeDestroy () {
  },
  computed: {
    ...mapActions('broker', ['SetBrokerAction']),
    ...mapState('broker', ['info_data']),
    ...mapGetters('broker', ['getBankAccountStatus', 'getBrokerAvaliable', 'getIrdStatus', 'getFormIsApproved', 'getIsIndividual', 'getFormIsSubmit'])
  }
}
</script>

<style scoped>
.dash_bottom {
  padding: .5rem;
}
.dash_title {
  padding: 30px;
  background: #ffffff;
  font-size: 24px;
  font-weight: 400;
  color: #080808;
  line-height: 28px;
}
.dash_info {
  max-width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.85);
}
.fade-leave-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-leave-enter, .fade-leave-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
@media only screen and (max-width:600px){
  .dash_info {
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.85);
}
}
</style>
