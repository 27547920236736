<template>
  <a-auto-complete
    :data-source="dataSource"
    style="width: 100%"
    :value='value'
    :placeholder='placeholder'
    @select="onSelect"
    @search="onSearch"
    @change="onChange"
    :disabled="disabled"
  />
</template>
<script>
import { googlePlaceAuto } from '@/api/common.js'
import debounce from 'lodash/debounce'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataSource: []
    }
  },
  methods: {
    onSearch: debounce(function (searchText) {
      if (searchText !== '') {
        const query = {
          search_content: encodeURIComponent(searchText)
        }
        googlePlaceAuto(query).then((res) => {
          if (res.predictions) {
            const temp = []
            res.predictions.map((item) => {
              temp.push(item.description)
            })
            this.dataSource = temp
          }
        })
      }
    }, 300),
    onSelect (value) {
      this.$emit('handleChange', this.prop, value)
    },
    onChange (value) {
      this.$emit('handleChange', this.prop, value)
    }
  }
}
</script>
